body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: Verdana, Geneva, sans-serif;
}

p {
    margin: 0;
}

button {
    font-family: inherit;
    font-size: inherit;
    border-radius: 0.5em;
    border: none;
    cursor: pointer;
    background-color: #eeeeee;
    color: black;
}

button:hover,
button:focus {
    background-color: #cccccc;
}

img {
    display: none;
}

img[src] {
    display: block;
}

.container {
    box-sizing: border-box;
    min-width: 20em;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 5em;
}

.main-color {
    color: #9696ff;
}

/* login page */

.login-container {
    justify-content: center;
}

.login-section {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 3em;
}

.about {
    margin-bottom: 5rem;
}

.login {
    background-color: #1db954;
    color: white;
    padding: 1.5em;
}

.login:hover,
.login:focus {
    background-color: #14813a;
}

.contact {
    text-decoration: none;
    margin: 4em 0 0;
    font-weight: bold;
}

.contact:focus,
.contact:hover {
    color: #7d7de6;
}

/* navigation */

.navigation {
    display: flex;
    flex-wrap: wrap;
    align-self: stretch;
}

.navbutton {
    margin: 1em;
    padding: 1em;
}

.logout {
    margin-left: auto;
}

/* selection page */

.selection,
.stats {
    width: 80%;
    max-width: 70em;
}

.form {
    display: flex;
}

.input,
.go {
    padding: 0.5em 0.75em;
    margin: 1em 0;
    border-radius: 0;
}

.input {
    font-family: inherit;
    font-size: inherit;
    background-color: #eeeeee;
    border: none;
    width: 50%;
}

.input:focus {
    outline: none;
}

.go {
    background-color: #9696ff;
    color: black;
}

.go:hover,
.go:focus {
    background-color: #7d7de6;
}

.library {
    margin: 1em 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    gap: 1.5em;
}

.playlist {
    flex: 1 1 17em;
    padding: 0.75em;
    display: flex;
    align-items: center;
}

.playlist-hidden {
    flex: 1 1 17em;
    visibility: hidden;
}

.playlist-image {
    max-width: 40%;
    margin-right: 1em;
    border-radius: 0.5em;
}

.playlist-title {
    margin: 0 auto;
    word-break: break-word;
}

.playlist-image[src] + .playlist-title {
    margin: 0;
    text-align: left;
}

/* stats page */

.loading {
    margin: 0 1em;
    text-align: center;
}

.stats-details {
    word-break: break-word;
}

.stats-img {
    width: 100%;
    cursor: pointer;
}

.stats-info {
    text-align: center;
}

.stats-title {
    font-weight: bold;
    font-size: 1.5rem;
    cursor: pointer;
    margin: 0.5em 0;
}

.stats-owner {
    margin-bottom: 1.5em;
}

@media (min-width: 30rem) {
    .stats-details {
        display: flex;
        align-items: center;
        margin-bottom: 1.5em;
    }

    .stats-img {
        max-width: 50%;
        margin-right: 1em;
    }

    .stats-info {
        text-align: left;
    }

    .stats-owner {
        margin-bottom: 1em;
    }

    .stats-title {
        margin: 0 0 1rem;
    }

    .stats-info p:last-of-type {
        margin-bottom: 0;
    }
}

.stats-subheading {
    font-size: 1.125rem;
    font-weight: bold;
    margin: 2em 0 1em;
}

.facts {
    list-style: none;
    padding: 0;
}

.fact {
    margin-bottom: 1em;
    text-align: left;
}

.track {
    display: flex;
    align-items: center;
    margin: 1em 0;
}

.track-img {
    max-width: 30%;
    margin-right: 1em;
    cursor: pointer;
}

.artists-toggle {
    margin-bottom: 1em;
    padding: 1em;
}

.include-features {
    cursor: pointer;
}

.artist {
    display: flex;
    align-items: center;
    margin: 1em 0;
    word-break: break-word;
}

.artist-img {
    max-width: 30%;
    margin-right: 1em;
    cursor: pointer;
}

@media (min-width: 60rem) {
    .stats {
        margin: 0 3em;
        width: auto;
    }

    .stats-header {
        display: flex;
        align-items: center;
    }

    .stats-details {
        flex: 0 1 40%;
        margin: 0 1em 0 0;
    }

    .facts {
        flex: 1 0 auto;
        display: flex;
        justify-content: space-around;
    }

    .fact-col {
        margin-right: 1em;
    }

    .stats-container {
        display: flex;
    }

    .artists {
        order: -1;
        flex: 1 0 0;
    }

    .tracks {
        flex: 0 0 35em;
    }

    .track-group {
        display: flex;
    }

    .track-col {
        flex: 1 1 50%;
        margin-left: 1em;
    }
}

@media (prefers-color-scheme: dark) {
    :root {
        color-scheme: dark;
    }

    body {
        background-color: #222222;
        color: white;
    }

    button {
        background-color: #555555;
        color: white;
    }

    button:hover,
    button:focus {
        background-color: #777777;
    }

    .input {
        background-color: #555555;
    }

    .input::placeholder {
        color: #999999;
    }
}
